/**
 */
export class Obj {

  /**
   * @param obj
   * @param func - Condition function.
   * @returns {{}}
   */
  static filter(obj, func) {

    const result = {}

    for (const [key, value] of Object.entries(obj)) {
      if (func(key, value)) {
        result[key] = value
      }
    }
    return result
  }

  /**
   * Checks if the object was created by the `Object` constructor.
   * Inspired by https://github.com/jonschlinkert/is-plain-object/blob/master/index.js
   * @param {Object} obj
   * @returns {boolean}
   */
  static isPlain(obj) {
    function isObject(val) {
      return val != null && typeof val === 'object' && Array.isArray(val) === false;
    }

    function isObjectObject(o) {
      return isObject(o) === true && Object.prototype.toString.call(o) === '[object Object]'
    }

    if (isObjectObject(obj) === false) {
      return false
    }
    // If has modified constructor
    const construct = obj.constructor
    if (typeof construct !== 'function') {
      return false
    }
    // If has modified prototype
    const proto = construct.prototype
    if (isObjectObject(proto) === false) {
      return false
    }
    // If constructor does not have an Object-specific method, most likely is a plain Object
    return proto.hasOwnProperty('isPrototypeOf') !== false;
  }

  /**
   * Checks if `Object` is empty.
   * @param obj
   * @returns {boolean}
   */
  static isPlainAndIsEmpty(obj) {
    return this.isPlain(obj) && Object.keys(obj).length === 0
  }

  /**
   * Checks if `Object` is not empty.
   * @param obj
   * @returns {boolean}
   */
  static isPlainAndIsNotEmpty(obj) {
    return this.isPlain(obj) && Object.keys(obj).length > 0
  }

  /**
   * @param obj
   * @param func
   * @returns {Object}
   */
  static map(obj, func) {

    const result = {};

    for (const [key, value] of Object.entries(obj)) {
      result[key] = func(key, value);
    }
    return result;
  }
}
