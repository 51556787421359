import * as React from "react"
import TagDetail from '../components/TagDetail'
import Layout from '../components/Layout'
import {graphql} from "gatsby";
import {Link, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import PostsList from '../components/PostsList'
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RssFeedIcon from "@mui/icons-material/RssFeed";


export const query = graphql`
  query ($language: String!, $id_odoo: Int!, $postIdsOdoo: [Int!]!) {

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    archiveCaveSoftTag(id_odoo: {eq: $id_odoo}, lang: {eq: $language}) {
      id_odoo
      write_date
      title
      content
      slug
      create_date_time
    }
    allArchiveCaveSoftPost(
      filter: {lang: {eq: $language}, id_odoo: {in: $postIdsOdoo}}
      sort: {fields: [write_date], order: [DESC]}
    ) {
      nodes {
        id_odoo
        write_date
        title
        content
        slug
        image_sources
        video_sources
        short_content
        tag_ids {
          id_odoo
          title
          slug
          content
          create_date_time
        }
      }
    }
  }
`

/**
 * @returns {JSX.Element}
 * @constructor
 */
function Tag({data: {archiveCaveSoftTag: tag, allArchiveCaveSoftPost: {nodes: posts}}, pageContext: {numPages, currentPage}}) {

  const {t} = useTranslation();
  const {defaultLanguage, language} = useI18next();

  // noinspection JSValidateTypes
  return (
    <Layout breadcrumbs={[<Link key={1} to={`/`}>{t("Posts")}</Link>]} showGoBack maxWidth="md">
      <TagDetail tag={tag}/>
      <Container maxWidth="sm" disableGutters>
        <Paper sx={{mx: 1, my: 2, p: 1, backgroundColor: 'aliceblue', display: 'flex', justifyContent: "center", alignItems: "center"}}>
          <Typography variant="body2">
            {t("Follow the posts of this page using this ")}
            <Box component="a" href={`${language === defaultLanguage ? '' : '/' + language}/tag/${tag.id_odoo}/rss.xml`}>
              {t("RSS feed")}
              <RssFeedIcon sx={{verticalAlign: 'bottom'}} color="warning"/>
            </Box>
          </Typography>
        </Paper>
      </Container>
      <PostsList posts={posts}/>
      <Container sx={{my: 7}} maxWidth="xs">
        <Pagination
          size="large"
          variant="outlined"
          shape="rounded"
          page={currentPage}
          count={numPages}
          renderItem={item => <PaginationItem
            {...item}
            component={Link}
            to={item.page === 1 ? `/tag/${tag.id_odoo}/?_=${tag.slug}` : `/tag/${tag.id_odoo}/posts/${item.page}/?_=${tag.slug}`}
            disabled={item.disabled || item.selected}
          />}
        />
      </Container>
    </Layout>
  )
}

export default Tag
