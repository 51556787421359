import React, {} from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import useDayJS from '../libraries/useDayJS'
import CardContent from "@mui/material/CardContent";
import * as tagTypes from "../types/tag";


function TagDetail({tag}) {

  const {i18n: {language}} = useTranslation();

  return (
    <Card>
      <CardContent sx={{px: {xs: 1, md: 2}}}>
        <Typography variant="title">{tag.title}</Typography>
        <Typography variant="info" gutterBottom title={useDayJS(language, {date_time: tag.create_date_time, format: 'LLLL'})}>
          {useDayJS(language, {date_time: tag.create_date_time, fromNow: true})}
        </Typography>
        <Box dangerouslySetInnerHTML={{__html: tag.content}}/>
      </CardContent>
    </Card>
  )
}

TagDetail.propTypes = {
  tag: tagTypes.tagType.isRequired,
};

export default TagDetail
