import {Obj} from 'js-util'
import PropTypes from 'prop-types'
//
// Tag
//
export let tagShape = {
  id_odoo: {propType: PropTypes.number.isRequired, isRelation: false, isSingleRelation: false, isManyRelation: false},
  title: {propType: PropTypes.string.isRequired, isRelation: false, isSingleRelation: false, isManyRelation: false},
  content: {propType: PropTypes.string.isRequired, isRelation: false, isSingleRelation: false, isManyRelation: false},
  slug: {propType: PropTypes.string.isRequired, isRelation: false, isSingleRelation: false, isManyRelation: false},
  post_ids: {propType: PropTypes.arrayOf(PropTypes.number), isRelation: true, isSingleRelation: false, isManyRelation: true},
  create_date_time: {propType: PropTypes.string.isRequired, isRelation: false, isSingleRelation: false, isManyRelation: false},
  write_date: {propType: PropTypes.string.isRequired, isRelation: false, isSingleRelation: false, isManyRelation: false},
}
tagShape = Obj.map(tagShape, (key, value) => {
    value.name = key
    return value
  }
)
export const tagType = PropTypes.shape(Obj.map(tagShape, (key, {propType}) => propType))
