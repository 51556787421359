import dayjs from 'dayjs'

/**
 */
export class Arr {

  /**
   * @param {Object[]} objects
   * @param {string} key
   * @param {string} order
   * @param {Boolean} parseISODate
   */
  static sortObjectsByKey(objects, key, order, {parseISODate = false} = {}) {

    const result = [...objects]
    result.sort(this.getCompareByKey(key, order, {parseISODate}))
    return result
  }

  /**
   * Returns a compare-objects-by-key function to be passed in array sort function.
   * @param {string} key
   * @param {string} order - Choose between ['ASC', 'DESC'].
   * @param {Boolean} parseISODate
   * @returns {(function(*=, *=): (number))|*}
   */
  static getCompareByKey(key, order, {parseISODate = false} = {}) {

    let getPosition = (a, b) => a[key] < b[key] ? -1 : 1
    if (parseISODate) {
      getPosition = (a, b) => dayjs(a[key] || '').isBefore(dayjs(b[key] || '')) ? -1 : 1
    }

    return (a, b) => {
      if (getPosition(a, b) < 0) {
        switch (order) {
          case 'ASC':
            return -1
          case 'DESC':
            return 1
          default:
            throw new Error("Missing order type.")
        }
      } else {
        switch (order) {
          case 'ASC':
            return 1
          case 'DESC':
            return -1
          default:
            throw new Error("Missing order type.")
        }
      }
    }
  }
}
