import i18n from 'i18next'


i18n.init({
  fallbackLng: 'en',
  // Very useful to diagnose problems
  // debug: DEBUG,
  returnEmptyString: false,
  namespaceSeparator: ':::::',
  keySeparator: '.....',
  contextSeparator: '_____',
})

export default i18n
export const t = (msg) => i18n['t'](msg)
